import { parseDate } from 'utils/dates';
import styles from './LiveTestingIndicator.module.css';

const MAX_LIVE_TESTING_DAYS = 90;

const LiveTestingIndicator = ({ lastTestDate }: { lastTestDate: string | null }) => {
  const date = parseDate(lastTestDate);

  if (!date || date <= Date.now() - MAX_LIVE_TESTING_DAYS * 24 * 60 * 60 * 1000) return null;

  return (
    <div className={styles.llLiveTestingIndicator}>
      <div className={styles.llTesting}>
        <div className={styles.llTestingIcon} />
        Live Testing
      </div>
    </div>
  );
};

export default LiveTestingIndicator;
