import styles from '../DetailCollapsible/DetailCollapsible.module.css';
import { ContaminantGroup } from 'api/types';
import clsx from 'clsx';
import { formatDate } from 'utils/dates';
import DetailCollapsible from 'components/v4/DetailCollapsible/DetailCollapsible';

const DetailContaminant = ({
  contaminantGroup,
  lastTestDate,
}: {
  contaminantGroup: ContaminantGroup;
  lastTestDate?: string;
}) => {
  const { name, contaminants, description, passed, standardsDescription } = contaminantGroup;

  const getPassedLabel = () => {
    if (passed === true) {
      return <div className={styles.pass}>Pass</div>;
    }
    if (passed === false) {
      return <div className={styles.fail}>Not Passed</div>;
    }
    return '-';
  };

  return (
    <DetailCollapsible
      label={
        <div className={styles.labelContent}>
          <div className={styles.labelItem}>
            <div className={styles.label}>Contaminant</div>
            <div className={styles.labelValue}>{name}</div>
          </div>
          <div className={clsx(styles.labelItem, styles.status)}>
            <div className={styles.label}>Status</div>
            <div className={styles.labelValue}>{getPassedLabel()}</div>
          </div>
          <div className={styles.labelItem}>
            <div className={styles.label}>Last Tested</div>
            <div className={styles.labelValue}>{formatDate(lastTestDate, 'short')}</div>
          </div>
        </div>
      }
    >
      <div className={styles.content}>
        <div className={styles.labelItem}>
          <div className={styles.label}>What is this?</div>
          <div>{description}</div>
        </div>
        {standardsDescription && (
          <div className={styles.labelItem}>
            <div className={styles.label}>What standards do we use and why?</div>
            <div>{standardsDescription}</div>
          </div>
        )}
        <table>
          <thead>
            <tr>
              <th>Analyte</th>
              <th>Amount</th>
              <th>Regulatory Limit</th>
            </tr>
          </thead>
          <tbody>
            {contaminants.map(({ name, value, outOfSpec }) => (
              <tr>
                <td>{name}</td>
                <td className={clsx({ [styles.outOfSpec]: outOfSpec })}>{value}</td>
                <td className={clsx({ [styles.outOfSpec]: outOfSpec })}>{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </DetailCollapsible>
  );
};

export default DetailContaminant;
