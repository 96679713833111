import { ProductSku } from 'api/types';
import DropDown from 'components/DropDown/DropDown';
import { WidgetProps } from 'components/types';
import { useState } from 'preact/hooks';
import LiveTestingIndicator from '../../v2/LiveTestingIndicator/LiveTestingIndicator';
import styles from './Widget.module.css';
import variables from '../../../theme/variables.module.css';
import ContaminantGroups from 'components/v4/ContaminantGroups/ContaminantGroups';
import ActiveIngredients from 'components/v4/ActiveIngredients/ActiveIngredients';
import ToolTip from 'components/ToolTip/ToolTip';
import CircleQuestion from 'components/Icons/CircleQuestion';
import DetailSideBar from 'components/v4/DetailSideBar/DetailSideBar';
import clsx from 'clsx';
import LightLabsIcon from 'components/Icons/LightLabsIcon';
import { useConfigContext } from '../../../context/ConfigContext';
import logger from 'utils/logger';
import { formatDate } from 'utils/dates';

const Widget = ({ product, style, hideActiveIngredients, hideContaminants, ...props }: WidgetProps) => {
  const { config } = useConfigContext();
  const { variant } = config;
  const { lastTestDate, skus = [] as ProductSku[] } = product;
  const [selectedSkuId, setSelectedSkuId] = useState<string | number | undefined>(skus?.[0]?.id);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  if (skus.length === 0) {
    logger.info('No SKUs to render');
    // If we lack data to show, don't render anything
    return;
  }

  // Has a variant been defined by the page
  const hasVariant = variant && skus.some(({ shopifyVariantId }) => shopifyVariantId == variant);

  // Only look at variant data
  const sku =
    skus.find(({ id, shopifyVariantId }) => (hasVariant ? shopifyVariantId == variant : id === selectedSkuId)) ?? {};
  const { bigBars = [], contaminantGroups = [] } = sku;
  const hasTestResults = !!bigBars.length || !!contaminantGroups.length;

  return (
    <div className={clsx(styles.widgetV4, variables.v4)} style={style} {...props}>
      <DetailSideBar
        sku={sku}
        lastTestDate={lastTestDate}
        isOpen={isSideBarOpen}
        onClose={() => setIsSideBarOpen(false)}
      />
      <div className={styles.widgetHeader}>
        <span className={styles.certified}>
          <a href="https://www.lightlabs.com" target="_blank" className={styles.logo}>
            <LightLabsIcon />
          </a>{' '}
          Test Results Verified by Light Labs
        </span>{' '}
        <LiveTestingIndicator lastTestDate={lastTestDate} />
      </div>
      <div className={styles.description}>
        <div>This product was last tested for product quality assurance on {formatDate(lastTestDate, 'short')}.</div>
        <div className={styles.detailLink} onClick={() => setIsSideBarOpen(true)}>
          View detailed results here.
        </div>
      </div>
      {!hasVariant && (
        <DropDown
          onSelect={setSelectedSkuId}
          selectedId={selectedSkuId}
          options={skus.map(({ id, name }) => ({ id: id, label: name }))}
        />
      )}

      {!hideActiveIngredients && !!bigBars?.length && <ActiveIngredients bigBars={bigBars} />}
      {!hideContaminants && !!contaminantGroups?.length && <ContaminantGroups contaminantGroups={contaminantGroups} />}
      {!hasTestResults && (
        <div className={styles.noResults}>
          We're rolling out testing for this product. Stay tuned for when it's ready!
        </div>
      )}
      {hasTestResults && (
        <div className={styles.ppb} onClick={() => setIsSideBarOpen(true)}>
          How do I read this?
          <CircleQuestion size={12} />
        </div>
      )}
    </div>
  );
};

export default Widget;
