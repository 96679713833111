import styles from './DetailSideBar.module.css';
import SideBar, { SideBarProps } from 'components/SideBar/SideBar';
import { ProductSku } from 'api/types';
import DetailActive from 'components/v4/DetailActive/DetailActive';
import DetailContaminant from 'components/v4/DetailContaminant/DetailContaminant';
import LightLabsIcon from 'components/Icons/LightLabsIcon';
import Close from 'components/Icons/Close';

type DetailSideBarProps = SideBarProps & {
  lastTestDate?: string;
  sku?: ProductSku;
};

const DetailSideBar = ({ sku, lastTestDate, ...props }: DetailSideBarProps) => {
  if (!sku) {
    return null;
  }

  const { bigBars = [], contaminantGroups = [] } = sku;

  return (
    <SideBar {...props}>
      <div className={styles.detailSidebar}>
        <div className={styles.body}>
          <div className={styles.closeIcon} onClick={() => props.onClose()}>
            <Close />
          </div>
          <div className={styles.title}>
            Results Verified by Light Labs <LightLabsIcon />
          </div>
          <div className={styles.description}>
            We conduct all tests in our ISO-17025 accredited lab using various scientific methods. Companies submit
            samples and are rigorously evaluated by a panel of scientists, ensuring compliance and accuracy of product
            claims.
          </div>
          {!!bigBars.length && (
            <section className={styles.section}>
              <div className={styles.sectionTitle}>Active Ingredients</div>
              {bigBars.map((bar) => (
                <DetailActive bar={bar} lastTestDate={lastTestDate} />
              ))}
            </section>
          )}
          {!!contaminantGroups.length && (
            <section className={styles.section}>
              <div className={styles.sectionTitle}>Contaminants</div>
              {contaminantGroups.map((group) => (
                <DetailContaminant contaminantGroup={group} lastTestDate={lastTestDate} />
              ))}
            </section>
          )}
        </div>
        <div className={styles.footer}>
          <div className={styles.title}>Light Labs Mission</div>
          <div>
            Our mission is to bring more transparency to the food system. We do so by enabling brands to easily test
            their products and share results with their customers.
          </div>
        </div>
      </div>
    </SideBar>
  );
};

export default DetailSideBar;
